<template>
    <v-app>
        <v-container fluid class="main-container">
            <v-stepper v-model="stepper" vertical>
                <div style="display: flex; justify-content: center; margin-top: 20px;">
                    <v-img
                        max-width="120"
                        src="@/assets/images/zapermenu-min.png"
                    />
                </div>
                <v-stepper-step :complete="stepper > 1" step="1">
                    Dados da empresa
                </v-stepper-step>

                <v-stepper-content step="1">
                    <v-card class="mb-12" elevation="0">
                        <v-card-text>
                            <v-text-field
                                label="CNPJ"
                                v-model="cnpj"
                                :loading="loading.cnpj"
                                @keypress.enter="consultaCnpj"
                                v-mask="['##.###.###/####-##']"
                                hide-details
                            />
                            <v-text-field
                                label="Razão Social"
                                v-model="razaoSocial"
                                hide-details
                            />
                            <v-text-field
                                label="Nome Fantasia"
                                v-model="nomeFantasia"
                                hide-details
                                @change="onFantasiaChange"
                            />
                            <v-text-field
                                label="Whatsapp"
                                v-model="whatsapp"
                                prefix="+55"
                                placeholder="(99) 99999-9999"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                hide-details
                            />
                            <v-text-field
                                label="Domínio"
                                v-model="dominio"
                                prefix="app.zapermenu.com.br/"
                                placeholder="domínio"
                                hide-details
                            />
                        </v-card-text>
                    </v-card>
                    <v-btn color="primary" @click="stepper = 2">Continuar</v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="stepper > 2" step="2">
                    Dados da Assinatura
                </v-stepper-step>

                <v-stepper-content step="2">
                    <v-card class="mb-12" elevation="0">
                        <v-card-text>
                            <v-radio-group
                                v-model="tipoPessoa"
                                label="Selecione o Documento para Gerar Assinatura:"
                                mandatory
                            >
                                <v-radio
                                    label="CPF"
                                    value="pf"
                                />
                                <v-radio
                                    label="CNPJ"
                                    value="pj"
                                />
                            </v-radio-group>
                            <v-select
                                :items="planos"
                                v-model="plano"
                                :item-text="e => `${e.name} (R$ ${(e.value / 100).toFixed(2)})`"
                                item-value="id"
                                label="Plano"
                                hide-details
                            />
                            <v-select
                                :items="formasPagamento"
                                v-model="formaPagamento"
                                label="Forma de pagamento"
                                hide-details
                            />
                            <v-text-field
                                v-if="formaPagamento === 'bolix'"
                                label="Vencimento (mínimo D+1)"
                                type="date"
                                v-model="vencimento"
                                hide-details
                            />
                            <v-checkbox
                                v-if="formaPagamento === 'bolix'"
                                v-model="markAsPaid"
                                label="Marcar primeira fatura como paga"
                            />
                            <v-text-field
                                v-if="formaPagamento === 'cartao'"
                                label="Data de cobrança"
                                type="date"
                                v-model="dataCobranca"
                                hide-details
                            />
                            <!-- <v-text-field
                                label="Valor implementação"
                                v-model="valorImplementacao"
                                prefix="R$"
                                v-mask="['#.##','##.##','###.##']"
                                hide-details
                            />
                            <v-text-field
                                label="Whatsapp do assinante"
                                v-model="assinaturaWhatsapp"
                                prefix="+55"
                                placeholder="(99) 99999-9999"
                                v-mask="['(##) ####-####', '(##) #####-####']"
                                hide-details
                            /> -->
                        </v-card-text>

                        <v-card-actions class="mt-6">
                            <v-btn text color="warning" @click="stepper = 1">Voltar</v-btn>
                            <v-spacer/>
                            <v-btn color="primary" @click="stepper = 3">Continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="stepper > 3" step="3">
                    Dados do Usuário Administrador
                </v-stepper-step>

                <v-stepper-content step="3">
                    <v-card class="mb-12" elevation="0">
                        <v-card-text>
                            <v-text-field
                                label="Nome Completo"
                                type="text"
                                v-model="nome"
                                hide-details
                            />
                            <v-text-field
                                label="CPF"
                                type="text"
                                v-model="cpf"
                                v-mask="['###.###.###-##']"
                                hide-details
                            />
                            <!-- <v-text-field
                                label="Data de Nascimento"
                                type="date"
                                v-model="nascimento"
                                placeholder="DD/MM/YYYY"
                                hide-details
                            /> -->
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        @keypress.enter="consultaCep"
                                        @blur="consultaCep"
                                        :loading="loading.cep"
                                        label="CEP"
                                        type="text"
                                        v-model="cep"
                                        v-mask="['#####-###']"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :items="ufs"
                                        label="UF"
                                        v-model="uf"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Município"
                                        type="text"
                                        v-model="municipio"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Bairro"
                                        type="text"
                                        v-model="bairro"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8">
                                    <v-text-field
                                        label="Rua"
                                        type="text"
                                        v-model="rua"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Número"
                                        type="text"
                                        v-model="numero"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>

                            <v-text-field
                                class="mt-8"
                                label="Email"
                                prepend-icon="mail"
                                :rules="[rules.email]"
                                v-model="email"
                                hide-details
                            />
                            <v-text-field
                                label="Senha"
                                v-model="password"
                                prepend-icon="lock"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="toggleShowPassword('showPassword')"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                hide-details
                            />
                            <v-text-field
                                label="Repetir Senha"
                                v-model="repetirSenha"
                                prepend-icon="lock"
                                :type="showPasswordConfirm ? 'text' : 'password'"
                                @click:append="toggleShowPassword('showPasswordConfirm')"
                                :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                hide-details
                            />
                        </v-card-text>
                        <v-card-actions class="mt-6">
                            <v-btn text color="warning" @click="stepper = 2">Voltar</v-btn>
                            <v-spacer/>
                            <v-btn
                                color="primary"
                                style="color: white"
                                @click="cadastrar"
                                :loading="loading.cadastrar"
                            >Cadastrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
            </v-stepper>

            <v-img
                src="@/assets/images/whats2.png"
                alt="Contato Whatsapp"
                @click="sendWhatsapp"
                width="80"
                class="whatsapp-button"
            />
        </v-container>
    </v-app>
</template>

<script>
import ufs from '../../utils/ufs';

export default {
    name: 'Registro',

    data: () => ({
        uf: '',
        cpf: '',
        rua: '',
        cep: '',
        nome: '',
        cnpj: '',
        email: '',
        numero: '',
        bairro: '',
        stepper: 1,
        dominio: '',
        password: '',
        whatsapp: '',
        municipio: '',
        nascimento: '',
        razaoSocial: '',
        nomeFantasia: '',
        // assinaturaWhatsapp: '',
        // valorImplementacao: '',
        repetirSenha: '',
        vencimento: '',
        dataCobranca: '',
        markAsPaid: false,
        tipoPessoa: null,
        plano: null,
        formaPagamento: null,
        ufs: [],
        showPassword: false,
        showPasswordConfirm: false,
        loading: {
            cep: false,
            cnpj: false,
            cadastrar: false,
        },
        planos: [],
        formasPagamento: [
            {
                value: 'bolix',
                text: 'Boleto/Pix',
            },
            {
                value: 'cartao',
                text: 'Cartão de Crédito',
            },
        ],
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inválido'
            },
        },
    }),

    mounted() {
        this.ufs = ufs;
        this.nascimento = this.moment().format('YYYY-MM-DD');
        this.vencimento = this.moment().add(1, 'days').format('YYYY-MM-DD');

        this.fillPlans();
    },

    methods: {
        cadastrar() {
            let validacao = this.validarFormulario();
            if (validacao) {
                alert(validacao);
                return;
            }

            const data = {
                uf: this.uf,
                cep: this.cep,
                rua: this.rua,
                cpf: this.cpf,
                nome: this.nome,
                name: this.nome,
                cnpj: this.cnpj,
                email: this.email,
                bairro: this.bairro,
                numero: this.numero,
                dominio: this.dominio,
                whatsapp: this.whatsapp,
                municipio: this.municipio,
                nascimento: this.nascimento,
                razao_social: this.razaoSocial,
                // assinatura_whatsapp: this.assinaturaWhatsapp,
                // valorImplementacao: this.valorImplementacao,
                corporateName: this.razaoSocial,
                fantasia: this.nomeFantasia,
                password: this.password,
                vencimento: this.vencimento,
                dataCobranca: this.dataCobranca,
                plano: this.plano,
                formaPagamento: this.formaPagamento,
                tipoPessoa: this.tipoPessoa,
                markAsPaid: this.markAsPaid,
            };

            this.loading.cadastrar = true;
            this.$http.post('empresa', data).then(resp => {
                const { data } = resp.data;
                if (data.type === 'warning') {
                    alert(data.msg);
                    return;
                }

                if (data.id) {
                    alert('Cadastro realizado com sucesso!\nFaça o login para continuar');
                    this.$router.push('/login');
                    return;
                }

                alert('Não foi possivel efetuar o cadastro');
            })
            .catch(e => alert('Não foi possivel efetuar o cadastro ' + e))
            .finally(() => (this.loading.cadastrar = false));
        },

        validarFormulario() {
            if (
                !this.email ||
                !this.nome ||
                !this.password ||
                !this.repetirSenha ||
                !this.cnpj ||
                !this.razaoSocial ||
                !this.nomeFantasia ||
                !this.whatsapp ||
                !this.dominio ||
                !this.cpf ||
                !this.nascimento ||
                !this.cep ||
                !this.uf ||
                !this.municipio ||
                !this.bairro ||
                !this.rua ||
                !this.plano ||
                !this.formaPagamento ||
                !this.tipoPessoa ||
                !this.numero
            ) {
                return 'Informe todos os campos';
            }

            if (this.password != this.repetirSenha) {
                return 'Senha não confere';
            }

            return false;
        },

        onFantasiaChange() {
            if (!this.nomeFantasia) {
                return;
            }

            this.dominio = this.slugify(this.nomeFantasia);
        },

        fillFields(data) {
            if (!data) {
                return;
            }

            this.nome = data.nome || '';
            this.razaoSocial = data.nome || '';
            this.nomeFantasia = data.fantasia || '';
            this.email = data.email || '';
            this.whatsapp = data.telefone || '';
            this.cep = data.cep || '';
            this.uf = data.uf || '';
        },

        async consultaCnpj() {
            // const cnpj = this.cnpj.replace(/[^\d]+/g, '');

            // if (cnpj.length < 14) {
            //     return;
            // }

            // this.loading.cnpj = true;
            // const consultaCnpj = this.$fn.httpsCallable('consultaCnpj');
            // const resp = await consultaCnpj({cnpj});
            // this.loading.cnpj = false;

            // this.fillFields(resp.data);
        },

        async consultaCep() {
            if (this.cep == '') {
                return;
            }

            const cep = this.cep.replace(/\D/g, '');
            let validacep = /^[0-9]{8}$/;

            if (!validacep.test(cep)) {
                alert('Informe um cep válido');
                // this.notify('Informe um cep válido', 'warning');
                return;
            }

            this.loading.cep = true;
            const request = await fetch(`https://viacep.com.br/ws/${cep}/json`, {
                method: 'GET',
            });
            let resp = await request.json();

            this.uf = resp.uf;
            this.bairro = resp.bairro;
            this.municipio = resp.localidade;
            this.rua = resp.logradouro;

            this.loading.cep = false;
        },

        fillPlans() {
            this.$http.post('empresa/get-planos').then(resp => {
                console.log(resp)
                this.planos = resp.data.data;
            });
        },

        sendWhatsapp() {
            const txt = 'Olá, gostaria de saber mais sobre o Zaper Menu';
            window.open('https://api.whatsapp.com/send?phone=5549988951210&text=' + encodeURIComponent(txt));
        },

        slugify(value) {
            const from = 'ąàáäâãåæćęęèéëêìíïîłńòóöôõøśùúüûñçżź_';
            const to = 'aaaaaaaaceeeeeeiiiilnoooooosuuuunczz-';
            const fromToRegex = new RegExp(from.split('').join('|'), 'g');
            const text = (value || '')
                .toString()
                .toLowerCase()
                .trim()
                .replace(fromToRegex, c => to.charAt(from.indexOf(c)))
                .replace(/(?![:&]+)[\s\W-]+/g, '-');

            return text;
        },

        toggleShowPassword(field) {
            this[field] = !this[field];
            this.$forceUpdate();
        },
    },
}
</script>

<style scoped>
    .main-container {
        height: 100%;
        display: flex;
        justify-content: center;
        background-color: #485461;
        background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
    }

    .row {
        height: 55px;
    }

    .whatsapp-button {
        position: fixed;
        z-index: 100;
        right: 15px;
        bottom: 15px;
        height: 80px;
        cursor: pointer;
    }
</style>
